<template>
    <div id="containerCafes" class="containerAgenda">
        <div class="enjeu">{{leTeasing}}</div>
        <h2>{{leTitre}}</h2>
        <div class="presentation" v-html="laDescription"></div>
        <div id="Cafes" class="propositions">
            <span v-if="!leTabProgramme">Le prochain café Lorfolio sera bientôt annoncé</span>
            <div v-if="leTabProgramme" class="itemProgramme" v-for="(leEvenement, numEvenement) in leTabProgramme" v-bind:class="{complet : leEvenement.etat == 'COMPLET'}" >
                <span class="icone"></span>
                <a v-if="leEvenement.etat != 'COMPLET'" class="bouton" :href="leEvenement.url" target="_blank">{{leEvenement.libelle_url}}</a>
                <div>
                    <div class="titre gauche">{{leEvenement.titre}}</div>
                    <div v-if="leEvenement.etat == 'COMPLET'" class="complet gauche">COMPLET</div>
                    <div class="sousTitre clear">{{leEvenement.sous_titre}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Cafes',
    props: {
            data: {
                type: Object
            }
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed: {
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        leTitre() {
            if (this.data && this.data.label)
                return(this.data.label);
            if (this.markOrientest) {
                return("Cafés Mon Orient’Est")
            }
            return("Les cafés Lorfolio")
        },
        leTeasing() {
            if (this.data && this.data.teasing)
                return(this.data.teasing);
            if (this.markOrientest) {
                return("Accompagnez Mon Orient’Est en ligne")
            }
            return("Partagez entre facilitateurs")
        },
        laDescription() {
            if (this.data && this.data.description)
                return(this.data.description); // description, teasing
            if (this.markOrientest) {
                return("La communauté des professionnels qui ont adopté Mon Orient’Est se réunit périodiquement lors de rencontres en ligne.<br/>Echangez sur les bonnes pratiques, participez aux choix d’évolution, et découvrez les nouveautés...")
            }
            return("La communauté des professionnels qui ont adopté Lorfolio se réunit périodiquement lors de rencontres en ligne.<br/>Echangez sur les bonnes pratiques, participez aux choix d’évolution, et découvrez les nouveautés...")
        },
        laConsigne() {
            if (this.data && this.data.consigne)
                return(this.data.consigne);
            return("Note : Les cafés sont réservés aux facilitateurs Lorfolio")
        },
        leTabProgramme() {
            if (this.data && this.data.evenements && this.data.evenements.length)
                return(this.data.evenements);
            return(null)
        },
    },
    created() {
        if (this.data) {
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #containerCafes {
        #Cafes {
        }
        .itemProgramme {
            .icone {
                background: url("/img/pro/rencontres/icon_cafe.svg") transparent no-repeat top left;
            }
        }
    }
</style>
