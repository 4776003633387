<template>
    <div id="containerAnimations" class="containerAgenda">
        <div v-if="0">
            <div class="enjeu"></div>
            <h2>Pas d'évènement prévu</h2>
            <div id="Animations" class="propositions">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Animations',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #containerAnimations {
        #Animations {
        }
    }
</style>
